import React, { useEffect } from 'react';
import styled from 'styled-components';

import bgPhoto from '../assets/img/emily.jpg';
import LandingPage from './LandingPage';
import { useLocation } from 'react-router-dom';
import hitPageOnAnalytics from '../utils/gTagHandler';

const AppRoot = styled.div`
  margin: 0;
  padding: 2em;
  background-color: #909866;

  & > * {
    z-index: 10;
  }
`;
// Sorry for these css, we were in rush :D
const BackGroundImage = styled.div<{ path: string }>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;

  background-image: url(${bgPhoto});
  background-repeat: no-repeat;
  background-size: cover;

  height: ${({ path }: { path: string }) =>
    path.includes('submitted') || path.includes('user') ? `1420px !important` : `2530px`};
  left: ${({ path }: { path: string }) =>
    path.includes('submitted') || path.includes('user') ? `-300px` : `-750px`};
  top: ${({ path }: { path: string }) =>
    path.includes('submitted') || path.includes('user') ? `-380px` : `-730px`};

  // height: 2530px;
  // left: -750px;
  // top: -730px;

  @media(min-width: 1280px) {
    // height: 2530px;
    height: ${({ path }: { path: string }) =>
    path.includes('submitted') || path.includes('user') ? `1620px` : `2530px`};
    left: -196px;
    top: -580px;
}
  
@media(min-width: 900px) {
  height: ${({ path }: { path: string }) =>
  path.includes('submitted') || path.includes('user') ? `1620px` : `2450px`};
  // left: -500px;
  left: ${({ path }: { path: string }) =>
  path.includes('submitted') || path.includes('user') ? `-300px` : `-250px`};
  top: -600px;
}

@media(max-width: 900px) {
  left: ${({ path }: { path: string }) =>
  path.includes('submitted') || path.includes('user') ? `-300px` : `-500px`};
}

@media(max-width: 550px) {
  left: ${({ path }: { path: string }) =>
  path.includes('submitted') || path.includes('user') ? `-300px` : `-630px`};
}

@media(max-width: 380px) {
  height: ${({ path }: { path: string }) =>
  path.includes('submitted') || path.includes('user') ? `1480px` : `2620px`};
  left: ${({ path }: { path: string }) =>
  path.includes('submitted') || path.includes('user') ? `-390px` : `-760px`};
  top: ${({ path }: { path: string }) =>
  path.includes('submitted') || path.includes('user') ? `-430px` : `-700px`};
}


  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgb(0, 0, 0, 0.2);
  }
`;

const EmilyBurns = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    hitPageOnAnalytics('/emily-main');
  }, []);


  return (
      <AppRoot>
        <BackGroundImage path={pathname} />
        <LandingPage celebName="emily" />
      </AppRoot>
  );
};

export default EmilyBurns;
