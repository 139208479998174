import React from 'react';
import { Card, Root } from './PrivacyPolicy';

export default function CookiePolicy() {
  return (
    <Root>
      <Card>
        <h3>COOKIE POLICY</h3>
        <p>
          PhotonLens (“us”, “we”, or “our”) uses cookies on photonlens.com (the “Service”). 
          By using the Service, you consent to the use of cookies.
        </p>
        <p>
          Our Cookies Policy explains what cookies are, how we use cookies, 
          how third-parties we may partner with may use cookies on the Service, 
          your choices regarding cookies and further information about cookies.
        </p>

        <h3>WHAT ARE COOKIES</h3>
        <p>
          Cookies are small pieces of text sent by your web browser by a website you visit. 
          A cookie file is stored in your web browser and allows the Service or a third-party 
          to recognize you and make your next visit easier and the Service more useful to you.
        </p>
        <p>Cookies can be “persistent” or “session” cookies.</p>
        <h3>PhotonLens USES COOKIES</h3>
        <p>
          When you use and access the Service, we may place a number of cookies files 
          in your web browser.
        </p>
        <p>
          We use cookies for the following purposes: to enable certain functions of 
          the Service, to provide analytics, to store your preferences, to enable 
          advertisements delivery, including behavioral advertising.
        </p>

        <p>
          We use both session and persistent cookies on the Service and we use different 
          types of cookies to run the Service:
        </p>
        <p>
         – Essential cookies. We may use essential cookies to authenticate users and prevent 
         fraudulent use of user accounts.
        </p>
        <p>
          For the full disclosure section, including all types of cookies 
          (from essentials to advertising cookies), create your own Cookies Policy.
        </p>

        <h3>THIRD-PARTY COOKIES</h3>
        <p>
          In addition to our own cookies, we may also use various third-parties cookies 
          to report usage statistics of the Service, deliver advertisements on and 
          through the Service, and so on.
        </p>

        <h3>WHAT ARE YOUR CHOICES REGARDING COOKIES</h3>
        <p>
          If you’d like to delete cookies or instruct your web browser to delete or refuse 
          cookies, please visit the help pages of your web browser.
        </p>

        <p>
          Please note, however, that if you delete cookies or refuse to accept them, 
          you might not be able to use all of the features we offer, you may not be 
          able to store your preferences, and some of our pages might not display properly.
        </p>
      </Card>
    </Root>
  );
}
