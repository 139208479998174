import React from 'react';
import styled from 'styled-components';

export const Root = styled.div`
  background-color: #ffffff;
  padding: 5em;
  height: 100vh;

  @media (max-width: 414px) {
    padding: 0;
    height: unset;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & > *::-webkit-scrollbar {
    display: none;
  }
`;

export const Card = styled.div`
  font-family: Roboto, sans-serif;
  height: 98%;
  margin: auto;
  background-color: #ffffff;
  overflow-x: scroll;
  padding: 2em;
  border-radius: 15px;
  color: #000000;
  box-shadow: -1px 1px 10px 0px #0000003b;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1080px) {
    max-width: 60%;
  }

  & > h3 {
    margin: 0.5em 0;
  }

  & > p {
    margin-bottom: 2em;
    line-height: 1.5;
  }
`;

const PrivacyPolicy = () => {
  return (
    <Root>
      <Card>
        <h3>PRIVACY POLICY</h3>
        <p>
          We are committed to safeguarding your privacy online and have developed
          this privacy policy to deal with issues which may concern you. Please read
          this policy to understand how your personal information will be treated.
          This policy may change from time to time so please check it periodically.
        </p>

        <h3>PERSONAL INFORMATION</h3>
        <p>
          The details which you provide about yourself and any information which 
          identifies you (such as your name, address, email address) (“personal information”) 
          will be collected by us when you register to use the site, when you submit such 
          information directly by filling in an online form, and when you browse, where 
          cookies may be used.{' '}
        </p>

        <h3>USE OF PERSONAL INFORMATION</h3>
        <p>
          This information will be held on our system and used only for setting up 
          and managing your website account, and to make available to you all 
          information relating to us.

        </p>

        <h3>PROTECTION OF INFORMATION</h3>
        <p>
          All data and information collected in this competition will be shared with 
          our co-organizers (Be-Hookd) to ensure the best possible experience for participants. 
          No data will be shared with external third parties unless we are required by law or 
          by a regulatory or government authority to do so. We do not normally link IP addresses 
          to anything personally identifiable, which means that you remain anonymous even though 
          we include your IP address in our aggregate information. However, we can and will 
          attempt to use IP addresses to identify a user when we feel it is necessary to enforce 
          compliance with our terms and conditions, or to protect our services and other users. 
        </p>

        <h3>COOKIES</h3>
        <p>
          Cookies are small pieces of information that are stored by your browser on your 
          computer’s hard drive. They make it possible for us to identify registered users 
          without needing to ask for registration details every time you access our site. 
          Using cookies makes accessing our site more convenient as you don’t need to remember 
          your password each time you enter.
        </p>

        <h3>PROTECTING INFORMATION</h3>
        <p>
          We take every precaution to protect your information. To this end all personal 
          information stored by us is kept on a server in a secure environment. Only 
          employees and approved contractors/developers who need the information to perform 
          a specific job are granted access to personally identifiable information. Regular 
          security reviews are held by us to ensure that the site remains safe and secure for 
          your protection. We cannot guarantee the security of data you disclose online and you 
          accept that the Internet is not completely secure and agree that we shall not be liable 
          for unauthorised use, distribution or destruction of your personal information.
        </p>

        <h3>UPDATING PERSONAL INFORMATION</h3>
        <p>
          If your personal information changes or if you no longer wish to subscribe to 
          the service, we will endeavour to correct, update or remove the personal information 
          provided to us as speedily as possible.
        </p>

        <h3>UNDER 18</h3>
        <p>
          If you are under 18, please ensure that you obtain your parent/guardian’s consent 
          beforehand whenever you provide personal information to the website. Users without 
          such consent are not allowed to provide us with personal information.
        </p>

        <h3>GOVERNING LAW</h3>
        <p>
          This privacy policy forms part of our Website Terms. Any dispute or claim arising 
          out of this policy and the use of your personal information will be governed by 
          English law and the courts of England and Wales have exclusive jurisdiction to 
          resolve any such dispute or claim.
        </p>
      </Card>
    </Root>
  );
};

export default PrivacyPolicy;
