import React from 'react';
import { Root, Card } from './PrivacyPolicy';

export default function TermsOfUse() {
  return (
    <Root>
      <Card>
        <h3>TERMS OF USE</h3>
        <h3>WEBSITE</h3>
        <p>
          The Website is owned and operated by PhotonLens. By using and/or
          visiting the Website, you hereby agree to these terms and conditions.
          These terms apply to all users of the Website.
        </p>

        <h3>ACCESS</h3>
        <p>
          PhotonLens hereby grants permission to use the Website provided that
          you do not copy any part of the Website in any medium without the
          prior written consent of PhotonLens, that you do not alter or modify
          any part of the Website other than as may be reasonably necessary to
          use the Website for its intended purpose and that you comply fully
          with the terms and conditions of use of this Website.
        </p>

        <h3>THIRD PARTY WEBSITES</h3>
        <p>
          The Website may contain links to third party websites that are not
          owned or controlled byPhotonLens. PhotonLens takes no responsibility
          for the content or privacy policies of any third party websites.
        </p>

        <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
        <p>
          The content of the Website including the text, software, scripts,
          graphics, photos, sounds, music, videos and interactive features, and
          the trademarks, service marks and logos are owned by or licensed to
          PhotonLens, and the content is subject to copyright and other
          intellectual property rights which belong exclusively to PhotonLens.
          The content of the Website may not be used, copied, reproduced,
          distributed, transmitted, broadcast, displayed, sold, licensed or
          otherwise exploited for any other purposes whatsoever without the
          prior written consent of PhotonLens. You hereby agree not to copy or
          distribute any of the content of the Website other than as expressly
          permitted under these terms. You further agree not to circumvent,
          disable or otherwise interfere with security related features of the
          Website or features that prevent or restrict the use or copying of any
          content or enforce limitations on use of the Website or the content
          thereof.
        </p>
        <p>
          All materials provided are intended for informational purposes only
          and are subject to change to withdrawal by PhotonLens at any time
          without notice.
        </p>
        <p>
          All comments, feedback, information or materials submitted to or
          through the Website shall be considered non-confidential and shall
          belong to PhotonLens. By submitting any materials to the Website, you
          hereby assign to PhotonLens all worldwide rights, title and interest
          in the copyright or other intellectual property rights to such
          material.
        </p>
        <p>
          You also agree that, in submitting material to the Website, you will
          not include any material which may damage PhotonLens or any third
          party or that is unlawful, obscene, defamatory, libelous, threatening,
          pornographic, harassing, hateful, racially or ethnically offensive, or
          encourages conduct that would be considered a criminal offence, give
          rise to civil liability, violate any law, or is otherwise
          inappropriate. PhotonLens disclaims all and any liability in
          connection with submissions uploaded onto the Website.
        </p>
        <p>
          PhotonLens does not in any way endorse any submission or any opinion,
          recommendation or advice expressed therein, except insofar as any
          opinion, recommendation or advice is clearly expressed to be by
          representatives of PhotonLens.
        </p>
        <p>
          PhotonLens does not permit any material which infringes copyright or
          any other intellectual property rights from being uploaded onto the
          Website. If PhotonLens is notified by any person that any material on
          the Website is infringing any other person’s rights, PhotonLens will
          remove all that infringing material and/or submissions forthwith,
          without notice to the user. PhotonLens also reserves the right at any
          time to remove any material or submissions which it deems to be
          unlawful, indecent, dishonest or in anyway detrimental to PhotonLens
          without prior notice.
        </p>
        <p>
          PhotonLens reserves the right to discontinue any aspect of the Website
          at any time.
        </p>

        <h3>WARRANTY DISCLAIMER</h3>
        <p>
          You agree that your use of the Website shall be at your sole risk. To
          the fullest extent permitted by law, PhotonLens, its officers,
          directors, employees, and agents disclaim all warranties, express or
          implied, in connection with the Website and your use thereof.
          PhotonLens, its officers, directors, employees, and agents make no
          warranties or representations about the accuracy or completeness of
          this Website’s content and assume no liability or responsibility for:
        </p>
        <p>any errors, mistakes, or inaccuracies of content;</p>
        <p>
          personal injury or property damage of any nature whatsoever, resulting
          from your access to and use of the Website, any unauthorised access to
          or use of our secure servers and/or any and all personal information
          and/or financial information stored therein;
        </p>
        <p>
          any interruption or cessation of transmission to or from the Website;
        </p>
        <p>
          any bugs, viruses, or the like which may be transmitted to or through
          the Website by any third party;
        </p>
        <p>
          and/or any loss or damage of any kind incurred as a result of the use
          of any content posted, emailed, transmitted, or otherwise made
          available via the Website.
        </p>

        <h3>LIMITATION OF LIABILITY</h3>
        <p>
          Nothing in these terms shall exclude or in any way limit PhotonLens’s
          liability for fraud, or for death or personal injury caused by its
          negligence, or any other liability to the extent such liability may
          not be excluded or limited as a matter of law. PhotonLens will not be
          liable for any loss of actual or anticipated income or profits, loss
          of contracts or for any special, indirect or consequential loss or
          damage of any kind howsoever arising and whether caused by tort
          (including negligence), breach of contract or otherwise, whether or
          not such loss or damage is foreseeable, foreseen or known. The Website
          is controlled and offered by PhotonLens in the United Kingdom.
          PhotonLens makes no representations and gives no warranties that the
          Website is appropriate or available for use in other locations. Those
          who access or use the Website from other jurisdictions do so at their
          own risk and are responsible for compliance with any local laws.
        </p>
      </Card>
    </Root>
  );
}
