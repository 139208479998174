import React, {useEffect} from 'react';
import styled from 'styled-components';

import bgPhoto from '../assets/img/garry.png';
import LandingPage from './LandingPage';
import { useLocation } from 'react-router-dom';
import hitPageOnAnalytics from '../utils/gTagHandler';


const AppRoot = styled.div`
  margin: 0;
  padding: 2em;
  background-color: #884d1c;

  & > * {
    z-index: 10;
  }
`;

const BackGroundImage = styled.div<{ path: string }>`
  position: absolute;
  z-index: 1;
  background-image: url(${bgPhoto});
  background-repeat: no-repeat;
  background-size: cover;
  height: unset;
  top: -80px;
  left: 0;
  bottom: 0;
  right: 0;

  @media (max-width: 2000px) {
    // height: 2200px;
    height: ${({ path }: { path: string }) =>
    path.includes('submitted') || path.includes('user') ? `unset` : `2200px`};
    // top: -450px;
    top: ${({ path }: { path: string }) =>
    path.includes('submitted') || path.includes('user') ? `-300px` : `-450px`};
  }

  @media (max-width: 900px) {
    height: 1100px;
    // height: unset;
    top: -150px;
  }

  @media (max-width: 414px) {
    background-size: cover;
    // height: 1502px;
    height: unset;
    top: -130px;
    left: -100px;
    bottom: 0;
    right: 0;
  }

  @media (max-width: 375px) {
    background-size: cover;
    // height: 1502px;
    height: unset;
    top: -130px;
    left: -100px;
    bottom: 0;
    right: 0;    
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0, 0.2);
  }
`;

export default function GaryBarlow() {
  const { pathname } = useLocation();

  useEffect(() => {
    hitPageOnAnalytics('/gary-main');
  }, []);

return (  
    <AppRoot>
      <BackGroundImage path={pathname} />
      <LandingPage celebName="gary" />
    </AppRoot>
);
}
