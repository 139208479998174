import React from 'react';
import styled from 'styled-components';
import { Redirect, useLocation, Link } from 'react-router-dom';

import { ReactComponent as Instagram } from '../assets/instagram.svg';
import { ReactComponent as Facebook } from '../assets/facebook.svg';
import { ReactComponent as TikTok } from '../assets/tik-tok.svg';
import { ReactComponent as Twitter } from '../assets/twitter.svg';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  & > * {
    margin-bottom: 1rem;
  }
`;

const SocialMediaLink = styled.a`
  padding: 6px;

  & > svg {
    height: 24px !important;
    width: 24px !important;
  }
  & > svg path {
    fill: #ffffff;
  }
`;

const FootNote = styled.div`
  color: #ffffff;

  font-size: .9em;
  // line-height: 1.2em;

  @media(min-width: 870px) {
    font-size: 0.9em;
    // line-height: 1;
  }
`;

const Links = styled(Link)`
  color: white;
`;

const Footer = () => {
  return (
    <Root>

      {/* <FootNote>
        <Links to="/competition-terms" target="_blank">
          Terms & Conditions
        </Links>
        {' '}and{' '}
        <Links to="/privacy-policy" target="_blank">
          Privacy Policy
        </Links>
      </FootNote> */}
      <FootNote>Follow PhotonLens on social media</FootNote>
      <div>
        <SocialMediaLink
          target="_blank"
          href='https://www.facebook.com/photonlens'
          rel="noopener noreferrer"
        >
          <Facebook />
        </SocialMediaLink>
        <SocialMediaLink
          target="_blank"
          href='https://www.instagram.com/photon_lens'
          rel="noopener noreferrer"
        >
          <Instagram />
        </SocialMediaLink>
        <SocialMediaLink
          target="_blank"
          href='https://twitter.com/photon_lens'
          rel="noopener noreferrer"
        >
          <Twitter />
        </SocialMediaLink>
        <SocialMediaLink
          target="_blank"
          href='https://www.tiktok.com/@photon_lens'
          rel="noopener noreferrer"
        >
          <TikTok />
        </SocialMediaLink>
      </div>
      <FootNote>
        Powered by{' '}
        <a
          style={{
            // textDecoration: 'none',
            color: 'currentcolor',
            fontFamily: 'roboto',
          }}
          target="_blank"
          href="https://www.be-hookd.com"
        >
          Be-Hookd
        </a>{' '}
      </FootNote>
    </Root>
  );
};

export default Footer;
