import React from 'react';
import { Root, Card } from './PrivacyPolicy';

export default function CompetitionTerms() {
  return (
    <Root>
      <Card>
        <h3>Competition Terms & Conditions</h3>
        <p>
          By entering the PHOTONLENS Competition you fully accept these general terms and 
          conditions of PHOTONLENS (“CT&C”) and you acknowledge that you fulfil 
          all eligibility requirements.

        </p>

        <h3>ELIGIBILITY</h3>
        <p>
          If you are under the age of 18 you will have to obtain the approval of your 
          parents or legal guardians to enter to the Competition. PHOTONLENS may ask you, 
          at any stage, to provide us with proof of age and/or identity and/or proof of 
          such approval, if relevant. PHOTONLENS may exclude any participants who are not 
          able to provide with such proof. Employees of PHOTONLENS or third party organizers 
          are not eligible to participate.

        </p>

        <h3>DATE OF COMPETITION</h3>
        <p>
          The Competition shall start and close on the date specified on the PHOTONLENS 
          Competition pages. Entries after the closing date will not be allowed.
        </p>

        <h3>HOW TO ENTER</h3>
        <p>
          Entrants can enter the Competition by submitting their full name and email 
          address on the designated Campaign pages. Further entries can be obtained 
          by following a PHOTONLENS social media channel (Facebook, Instagram, Twitter, Tiktok) 
          or sharing the Campaign page on Facebook or Twitter. There is no entry fee and no 
          purchase necessary to enter the Competition. By entering the Competition, you will 
          not be eligible to receive any prizes awarded in any other Competition unless you 
          enter each Competition separately. If for any reason a technical interruption, 
          fault or site failure occurs when entering online, PHOTONLENS does not take any 
          responsibility for incomplete entries and any incomplete entries will not be 
          considered valid for entry in the Competition.
        </p>

        <h3>THE WINNER</h3>
        <p>
          The winner will be chosen at random from all correct entries. The winner will 
          be notified by email within five business days after the closing date of the 
          Competition. If the winners cannot be contacted or do not claim the prize 
          within 48 hours, PHOTONLENS reserves the right to withdraw the prize from the 
          winner and pick a replacement winner. PHOTONLENS’s decision in respect of all 
          matters to do with the Competition will be final and shall not be subject to 
          review or appeal by any entrant or by any third party.
        </p>

        <h3>PRIZE</h3>
        <p>
          The promotional prize shall be a PHOTONLENS VR headset as indicated on the Campaign page. 
          The prize is as stated and no cash or other alternatives will be offered. 
          The prizes are not transferable. Prizes are subject to availability and we 
          reserve the right to substitute any prize with another of equivalent value 
          without giving notice.

        </p>

        <h3>PERSONAL DATA</h3>
        <p>
          Each winner agrees that, by participating in the Competition, PHOTONLENS may use 
          the winner’s name for promotional purposes, which may include publication of 
          the winner’s name in any media. {' '}
        </p>

        <h3>LIMITATION OF LIABILITY</h3>
        <p>
          By entering the Competition each entrant agrees to release, discharge and hold 
          harmless PHOTONLENS, its legal representatives, respective officers, 
          directors, employees and agents from any damages whatsoever suffered or 
          sustained in connection with the Competition or the acceptance of the prize.

        </p>

        <h3>GENERAL TERMS & CONDITIONS</h3>
        <p>
          PHOTONLENS reserves its right to cancel the Competition at any times or 
          change the provisions of the General Terms & Conditions at its sole 
          discretion and such change shall have immediately effect upon the publication thereof.

        </p>

        <h3>GOVERNING LAW</h3>
        <p>
          The Competition is governed by the laws of England and Wales and Entrants 
          to the Competition submit to the jurisdiction of the courts of England and Wales.
        </p>
      </Card>
    </Root>
  );
}
