import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import PrivacyPolicy from './components/PrivacyPolicy';
import EmilyBurns from './components/EmilyBurns';
import GaryBarlow from './components/GaryBarlow';
import CookiePolicy from './components/CookiePolicy';
import TermsOfUse from './components/TermsOfUse';
import CompetitionTerms from './components/CompetitionTerms';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" render={() => (window.location.href = "https://www.be-hookd.com")} exact />
        <Route path="/emily-burns-photonlens" component={EmilyBurns} />
        <Route path="/gary-barlow-photonlens" component={GaryBarlow} />

        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/cookie-policy" component={CookiePolicy} />
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/competition-terms" component={CompetitionTerms} />
      </Switch>
    </Router>
  );
}

export default App;
