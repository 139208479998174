import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { createGlobalStyle } from 'styled-components';
import './index.css';

const GlobalStyles = createGlobalStyle`

  html, body, div, span, iframe,
  h1, h2, h3, h4, h5, h6, p,
  a, small, strike, strong, sub, sup, tt, var,
  b, u, i, center, ul, li,
  fieldset, form, label, legend {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }
  
  body {
    line-height: 1;
  }

  ol, ul {
    list-style: none;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0; padding: 0;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
