
/*global gtag*/

let initialised = false;

function init() {
    if (!initialised) {
        const targetId: string = 'G-BJ9QNJDXKX';
        const domains: string[] = ['photolens-competition.web.app', 'app.photonlens.com'];
        if (targetId) {
            gtag('config', targetId, {
                linker: { domains },
                'send_page_view': false,
                'custom_map': { 'dimension1': 'user_type', 'dimension2': 'user_id' }
            });
            initialised = true;
        } else console.error('Failed initialising GA - no target ID');
    }
}


export default function hitPageOnAnalytics(path: string | undefined) {
    init();
    if (path) {
        console.log("path :: ", path)
        gtag('event', 'page_view', {
            'page_path': path,
        });
    } else console.error('Calling hitPageOnAnalytics without defined path');
}
